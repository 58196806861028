// Base
@import "feelbettr/variables.less";
@import "themes/uikit-min-theme-default.less";
@import "themes/base.less";
@import "themes/typography.less";
@import "themes/contact.less";
@import "themes/header-overlay.less";

@bg-color: #F3F3F3;
@link-hover-color: @primary;
@global-font-size: 14px;
@frame-bg: #fff;
@global-font-family:  "azo-sans-web",sans-serif;

html {
	background:@bg-color
}

body {
	@media screen and (min-width: @breakpoint-small){
		padding:30px
	}
}

.header.uk-light nav{
	.uk-navbar-dropdown {
		background:#121212 !important;

		a {
			color:#fff !important;

			&:hover {
				color:#999 !important
			}
		}
	}
}

.uk-light .uk-navbar-nav li.uk-active li a,
.uk-light .uk-navbar-nav li.uk-active {
	border-bottom:0 !important
}



#header-container {
	.header-default,.header-compact {
		width: 100%;
    	padding: 0;
		max-width: 90%;

		@media screen and  (max-width:1200px) {
			max-width:90%
		}
		margin: 30px auto;
	}

	.header-center {
		margin-top:60px
	}

	.header-compact,
	.header-large {

		margin-top:40px;

		@media screen and  (max-width:1200px) {
			max-width:84%
		}

		a {
			padding:0  !important;
			margin-right:30px;
		}

		#responsive-menu-icon {
			margin-right:30px;

			@media screen and  (max-width:640px) {
				margin-top:30px
			}
		}

		.responsive-menu-wrap {
			width:200px;
		}

		@media screen and (max-width: @breakpoint-small) {
			h2.site-title {
				font-size: 1.6rem;
				margin-top: 12px !important;
			}
		}
	}

	.header-large {
		width:80%;

		#responsive-menu-icon  {
			top: 45px;
			right: 30px;
		}
	}
}


@media screen and (max-width: @breakpoint-small){
	.uk-container {
		width:88%;
		padding:0 6%
	}

	.uk-flex-middle,
	.uk-flex-top,
	.uk-flex-bottom {
		.uk-container {
			margin-top:30%
		}
	}

	#header-container .header-compact, #header-container .header-default {
		max-width:88%
	}

	
}

@media screen and (max-width: @breakpoint-medium){
	#responsive-menu-icon{
		top: -30px;
		margin-right: 0;
	}
}




li.uk-active a {border-bottom:4px solid;}